import React from 'react';
import {STYLES} from "../../App";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

const IR = () => {
    return (
        <div>
            <Helmet>
                <meta name={'description'}
                      content={'Инвестиционный ремонт в Екатеринбурге, от 30 тыс. руб./м2 &#128383; 8-912-24-93-713'}/>
                <title>Инвестиционный ремонт в Екатеринбурге | Flippium</title>
            </Helmet>
            <Container className={STYLES.cards} fluid={'true'}>
                <h1>Инвестиционный ремонт в Екатеринбурге</h1>
                Инвестиционный ремонт — это комплекс мероприятий по обновлению и модернизации недвижимости с целью
                повышения её рыночной стоимости и привлечения потенциальных арендаторов или покупателей. Такой ремонт
                включает в себя не только косметические изменения, но и капитальные работы, направленные на улучшение
                функциональности, энергоэффективности и эстетики объекта, что в дальнейшем обеспечивает возврат
                вложенных средств и получение прибыли. Так как инвестиционный ремонт призван выделить Вашу квартиру или
                дом на рынке недвижимости, то его неотъемлемой частью является <a
                href={'/homestaging'}>хоумстейджинг</a>.
                Наше агентство занимается инвестиционными ремонтами с 2022 года и обладает всеми необходимыми
                компетенциями для организации ремонтов под ключ с максимальной эффективностью.
                Примеры реализованныех проектов смотрите в разделе <a
                href={'/cases/'}>кейсы</a>
                <hr/>
                Если Вам требуется выполнить инвестиционный ремонт - <a href={'/contacts'}>напишите нам</a>
            </Container>

        </div>
    );
};

export default IR;