import React from 'react';
import {STYLES} from "../../App";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const Flipping = () => {
    return (
        <div>
            <Helmet>
                <meta name={'description'}
                      content={'Флиппинг в Екатеринбурге, вход от 3,0 млн. руб. &#128383; 8-912-24-93-713'}/>
                <title>Флиппинг в Екатеринбурге | Flippium</title>
            </Helmet>
            <Container className={STYLES.cards} fluid={'true'}>
                <h1>Флиппинг в Екатеринбурге</h1>
                Флиппинг - это покупка квартиры по минимально возможной рыночной цене, выполнение <a href={'/ir'}>инвестиционного
                ремонта</a> с дальнейшей продажей по максимальной рыночной цене. Цикл такого проекта составляет 3-4
                месяца, средняя рентабельность (ROI) 50%. Наше агентство занимается
                флиппингом с 2022 года и обладает всеми необходимыми компетенциями для реализации проектов c
                максимальной эффективностью.
                Примеры реализованныех проектов и их финансовые показатели смотрите в разделе <a
                href={'/cases/'}>кейсы</a>
                <hr/>
                <Row xs={'1'} sm={'2'}>
                    <Col>
                        <h2>Предлагаемая схема взаимодействия</h2>
                        <ul>
                            <li>
                                Вы предварительно <a href={'/contacts'}>связываетесь</a> с нами и наше агентство,
                                используя собственное программное обеспечение, подбирает возможные варианты для
                                инвестирования
                            </li>
                            <li>После согласования квартира приобретается и оформляется на инвестора (максимальная
                                доходность достигается при использовании «ипотечного плеча»)
                            </li>
                            <li>Между инвестором и нашим агентством заключается агентский договор с указанием
                                размера затрат и методом распределения маржинального дохода (возможно использование
                                ЭДО)
                            </li>
                            <li>За счет инвестиций наше агентство организует ремонт в квартире</li>
                            <li>Квартира выставляется в продажу и рекламу силами нашего агентства</li>
                            <li>Денежные средства от продажи квартиры поступают на счет инвестора</li>
                            <li>На основании фактических данных о доходах и расходах подписывается соглашение о
                                распределении маржинального дохода с последующим перечислением вознаграждения в
                                адрес нашего агентства
                            </li>
                        </ul>
                        <br/>
                        Если Вас заинтересовало наше предложение - <a href={'/contacts'}>напишите нам</a>
                    </Col>
                    <Col>
                        <img src={'/img/about.jpg'} alt={'About'} width={'100%'}/>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default Flipping;