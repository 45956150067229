import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './flippium.css';
import {BrowserRouter, Navigate, Routes, Route} from "react-router-dom";
import About from "./pages/About";
import Flipping from "./pages/services/Flipping";
import Contacts from "./pages/Contacts";
import Analyze from "./pages/Analyze";
import CNavbar from "./components/common/CNavbar";
import Recents from "./pages/Recents";
import Browser from "./pages/Browser";
import Cases from "./pages/Cases";
import Homestaging from "./pages/services/Homestaging";
import IR from "./pages/services/IR";
import {Helmet} from "react-helmet";

export const STYLES = {
    cards: "p-3 m-3 shadow-sm bg-white",
    service_cards: "p-3",
}

function App() {
  return (
      <div>
          <Helmet>
              <title>Flippium</title>
          </Helmet>
          <BrowserRouter>
              <CNavbar/>
              <div className={"main-container"}>
              <Routes>
                  <Route path="/" element={<About/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/contacts" element={<Contacts/>}/>
                  <Route path="/flipping" element={<Flipping/>}/>
                  <Route path="/ir" element={<IR/>}/>
                  <Route path="/homestaging" element={<Homestaging/>}/>
                  <Route path="/cases" element={<Cases/>}/>
                  <Route path="/recents" element={<Recents/>}/>
                  <Route path="/browser" element={<Browser/>}/>
                  <Route exect path="/analyze" element={<Analyze/>}/>
                  <Route path="/analyze/*" element={<Analyze/>}/>
                  <Route
                      path="*"
                      element={<Navigate to="/" replace />}
                  />
              </Routes>
              </div>
          </BrowserRouter>
        <div align={"center"}>
            &copy; ИП Фомичев Алексей Владимирович, 2022-2025
        </div>
      </div>
  );
}

export default App;
