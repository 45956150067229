import React from 'react';
import { useNavigate } from "react-router-dom"
import {STYLES} from "../App";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const About = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>Наши услуги | Flippium</title>
            </Helmet>
            <Container className={STYLES.cards} fluid={'true'}>
                <h1>Об агентстве <i>flippium</i></h1>
                Наше агенство занимается инвестиционными ремонтами квартир и домов в городе Екатеринбурге под ключ. Что
                такое инвестиционный ремонт? Это комплекс мероприятий, благодаря которым ремонт становится
                выгодной инвестицией для собственника недвижимости, вложения в который окупается через рост рыночной
                стоимости квартиры или дома (дополнительная маржинальная прибыль при продаже), сокращение сроков
                продажи недвижимости или повышение стоимости сдачи собственности в аренду
                <br/><br/>
                <h2>Наши услуги</h2>
                <Container>
                    <Row xs={'1'} sm={'3'}>
                        <Col>
                            <Card style={{width: '14rem'}} className={STYLES.service_cards}>
                                <Card.Img variant={'top'} src={'/img/services/icon_ir.png'} style={{width: '50px', height: '50px'}}/>
                                <Card.Title style={{height: '50px'}}>
                                    Инвестиционный ремонт
                                </Card.Title>
                                <Card.Text style={{height: '220px'}}>
                                    Мы сами разработаем проект и организуем ремонт под-ключ Вашей квартиры или дома

                                </Card.Text>

                                <Button variant={'primary'} onClick={() => navigate('/ir')}>Подробнее</Button>
                            </Card>
                        </Col>
                        <Col>
                        <Card style={{width: '14rem'}} className={STYLES.service_cards}>
                            <Card.Img variant={'top'} src={'/img/services/icon_flipping.png'} style={{width: '50px', height: '50px'}}/>
                            <Card.Title style={{height: '50px'}}>Флиппинг</Card.Title>
                            <Card.Text style={{height: '220px'}}>
                                Мы подберем для Вас предложение на рынке недвижимости, сделаем инвестиционный ремонт и организуем продажу готового объекта с максимальной прибыльностью
                            </Card.Text>
                            <Button variant={'primary'} onClick={() => navigate('/flipping')}>Подробнее</Button>
                        </Card>
                    </Col>
                        <Col>
                        <Card style={{width: '14rem'}} className={STYLES.service_cards}>
                            <Card.Img variant={'top'} src={'/img/services/icon_homestaging.png'} style={{width: '50px', height: '50px'}}/>
                            <Card.Title style={{height: '50px'}}>Хоумстейджинг</Card.Title>
                            <Card.Text style={{height: '220px'}}>
                                Мы увеличим привлекательность Вашей недвижимости для потенциальных покупателей или арендаторов через правильное оформление пространства
                            </Card.Text>
                            <Button variant={'primary'} onClick={() => navigate('/homestaging')}>Подробнее</Button>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};

export default About;