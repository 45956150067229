import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import CInputUrl from "../components/CInputURL";
import COffer from "../components/COffer";
import CFinForm from "../components/CFinForm";
import Fin from "../api/fin";
import Offer from "../api/offer";
import CFinFormSubmit from "../components/CFinFormSubmit";
import CMap from "../components/CMap";
import {STYLES} from "../App";
import {Helmet} from "react-helmet";

const DEFAULT_ERROR_MESSAGE = "Произошла ошибка<br/>Пожалуйста, обновите страницу"

const Analyze = () => {
    const url = useParams()['*']

    const [offerData, setOfferData] = useState({loaded: false, data: {}})
    const [finData, setFinData] = useState({loaded: false, data: {}})
    const [adjacentData, setAdjacentData] = useState({loaded: false, data: []})
    const [historyData, setHistoryData] = useState({loaded: false, data: []})
    const [stateMap, stateMapSetter] = useState({})
    const [errorState, setErrorState] = useState({error: false, message: ""})
    const [errorStateOffer, setErrorStateOffer] = useState({error: false, message: ""})
    const [errorStateFin, setErrorStateFin] = useState({error: false, message: ""})
    const [errorStateAdjacent, setErrorStateAdjacent] = useState({error: false, message: ""})

    const navigate = useNavigate()
    const redirect = (new_url) => {
        setErrorState({error: false, message: ""})
        if(url !== new_url) {
            navigate(`/analyze/${new_url}`)
        }
    }

    // Обновляем данные объявления при изменении адреса
    useEffect( () => {
        if (url) {
            // Если в адресе только цифры - это идентификатор offer_id
            if(/^\d+$/.test(url)) {
                setOfferData({...offerData, loaded: false})
                Offer.getById(Number(url)).then((response) => {
                    if (response.status === 200) {
                        setOfferData({loaded: true, data: response.data.data})
                    } else {
                        setErrorState({...errorState, error: true, message: response.data})
                    }
                })
            } else {
                Offer.getByUrl(url).then((response) => {
                    if (response.status === 200) {
                        redirect(response.data.data.offer_id)
                    } else {
                        setErrorState({...errorState, error: true, message: response.data})
                    }
                })
            }

        }
    }, [url])


    // Обновляем расчет финансовых показателей проекта при изменении исходного объявления
    useEffect(() => {
        setFinData({...finData, loaded: false})
        if(offerData.loaded) Fin.getById(offerData.data.offer_id).then((response) => {
            if(response.status === 200) {
                setFinData({loaded: true, data: response.data.data})
            } else {
                setErrorState({...errorState, error: true, message: response.data})
            }
        })
    }, [offerData]);


    // Обновляем карту при изменении исходного объявления
    useEffect( () => {
        if(offerData.loaded)
                stateMapSetter({
                center: [offerData.data.lat,
                    offerData.data.lng],
                zoom: 16
            })
    }, [offerData])

    // Обновляем список из похожих объявлении при изменении исходного объявления
    useEffect( () => {
        setAdjacentData({...adjacentData, loaded: false})
        setHistoryData({...historyData, loaded: false})
        if(offerData.loaded) {
            Offer.getAdjacentById(offerData.data.offer_id).then((response) => {
                if(response.status === 200) {
                    setAdjacentData({loaded: true, data: response.data.data})
                } else {
                    setErrorState({...errorState, error: true, message: response.data})
                }
            })
            Offer.getAdjacentById(offerData.data.offer_id, false, true).then((response) => {
                if(response.status === 200) {
                    setHistoryData({loaded: true, data: response.data.data})
                } else {
                    setErrorState({error: true, message: response.data})
                }
            })
        }
    }, [offerData])

    const submitFinData = async () => {
        Fin.getCalculation(finData.data).then((response) => {
            if(response.status === 200) {
                setFinData({loaded: true, data:response.data.data})
            } else {
                setErrorState({...errorState, error: true, message: response.data.message})
            }
        })
    }

    const finDataCallback = (newData) => {
        const oldFinData = finData.data
        const newFinData = {...oldFinData, ...newData}
        const callBackData = {...finData, data:newFinData}
        return setFinData(callBackData)
    }

    return (
            <div>
                <Helmet>
                    <title>Анализатор | Flippium</title>
                    <link rel="canonical" href="/analyze"/>
                </Helmet>
                <div className={STYLES.cards}>
                    <CInputUrl value={offerData.data.url} callback={redirect}/>
                </div>
                {
                    errorState.error ?
                        <div>{errorState.message.description}</div>
                        :
                        <div></div>
                }
                {
                    offerData.loaded ?
                    <div className={STYLES.cards}>
                        <h3>Параметры объявления</h3>
                        <COffer offers={[offerData.data]}/>
                    </div>
                    :
                    <h3>{ url ? <div align={"center"}>Загружаю параметры объявления...</div> : ""}</h3>
                }
                {
                    offerData.loaded && adjacentData.loaded ?
                    <div>
                        <div className={STYLES.cards}>
                            <h3>Ближайшие конкуренты</h3>
                            <br/>
                            <CMap items={[offerData.data, ...adjacentData.data]} state={stateMap}/>
                            <br/>
                            <COffer offers={adjacentData.data}/>
                        </div>
                    </div>
                    :
                        offerData.loaded ?
                            <h3>{ url ? <div align={"center"}>Загружаю список возможных конкурентов...</div> : ""}</h3>
                        :
                            <h3></h3>
                }

                { offerData.loaded && historyData.loaded ?
                    <div>
                        <div className={STYLES.cards}>
                            <h3>История объявлений</h3>
                            <br/>
                            <CMap items={[offerData.data, ...historyData.data]} state={stateMap}/>
                            <br/>
                            <COffer offers={historyData.data}/>
                        </div>
                    </div>
                    :
                    offerData.loaded ?
                        <h3>{ url ? <div align={"center"}>Загружаю историю продаж...</div> : ""}</h3>
                    :
                    <h3></h3>
                }

                { /* finData.loaded */ true ?
                    <div className={STYLES.cards}><h3>Результаты расчетов</h3> <CFinForm data={finData.data} callback={finDataCallback}/><CFinFormSubmit callback={submitFinData}/></div> :
                    offerData.loaded ?
                        <h3>{ url ? <div align={"center"}>Загружаю результаты расчета финансовых показателей...</div> : ""}</h3>
                        :
                        <h3></h3>
                }
            </div>
    );
};

export default Analyze;
