import React from 'react';
import {STYLES} from "../App";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const Cases = () => {
    return (
    <div>
        <Helmet>
            <title>Завершенные кейсы | Flippium</title>
            <link rel="canonical" href="/cases"/>
        </Helmet>
        <Container className={STYLES.cards} fluid={'true'}>
            <h2>Последние кейсы агентства <i>flippium</i></h2>
            <br/>
            <Row xs={'1'} sm={'2'}>
                <Col>
                    ЖК Татлин; 35,0 м2
                    <ul>
                        <li>
                            Покупка: 4,9 млн. руб. (в ипотеку)
                        </li>
                        <li>
                            Ремонт: 1,2 млн. руб.
                        </li>
                        <li>
                            Продажа: 7,0 млн. руб.
                        </li>
                        <li>
                            ROI: 44% (с учетом платежей по ипотеке)
                        </li>
                    </ul>
                </Col>
                <Col>
                    <Carousel interval={null} variant={'dark'}>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/g4/5.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/g4/4.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/g4/3.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/g4/2.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/g4/1.jpg'} alt={''}/></div></Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
            <Row><br/></Row>

            <Row xs={'1'} sm={'2'}>
                <Col>
                    ТСН Машинистов, 3; 48,5 м2
                    <ul>
                        <li>
                            Покупка: 7,5 млн. руб. (в ипотеку)
                        </li>
                        <li>
                            Ремонт: 2,0 млн. руб.
                        </li>
                        <li>
                            Продажа: 12,0 млн. руб.
                        </li>
                        <li>
                            ROI: 62% (с учетом платежей по ипотеке)
                        </li>
                    </ul>
                </Col>
                <Col>
                    <Carousel interval={null} variant={'dark'}>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/m1/5.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/m1/4.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/m1/3.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/m1/2.jpg'} alt={''}/></div></Carousel.Item>
                        <Carousel.Item><div className={'w-100 d-flex flex-column justify-content-center align-items-center'}><img src={'/img/cases/m1/1.jpg'} alt={''}/></div></Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    </div>
        );
};

export default Cases;