import React from 'react';
import {Navbar, Nav, Container, NavDropdown} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";

const CNavbar = (props) => {
    const location = useLocation()
    return (
        <Navbar bg="dark" variant={'dark'} expand="sm" className={"p-sm-0"}>
            <Container className={'main-container'}>
            <Navbar.Brand as={Link} to="/" className={"navbar-logo"}>Flippium</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            {/*<Container>*/}
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav activeKey={location.pathname.match(/(\/[^/]*\/?)/).pop()} className="ms-auto me-0">
                        <Nav.Link as={Link} to={'/about/'} eventKey={"/about/"}>О нас</Nav.Link>
                        <NavDropdown title={'Наши услуги'} id={'nav-dropdown-services'}>
                            <NavDropdown.Item href={'/ir'}>Инвестиционный ремонт</NavDropdown.Item>
                            <NavDropdown.Item href={'/flipping'}>Флиппинг</NavDropdown.Item>
                            <NavDropdown.Item href={'/homestaging'}>Хоумстейджинг</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={'Инструменты'} id={'nav-dropdown-tools'}>
                            <NavDropdown.Item href={'/analyze/'}>Анализатор</NavDropdown.Item>
                            <NavDropdown.Item href={'/calc/'}>Калькулятор</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to={'/cases/'} eventKey={"/cases/"}>Кейсы</Nav.Link>
                        <Nav.Link as={Link} to={'/contacts/'} eventKey={"/contacts/"}>Контакты</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            {/*</Container>*/}
            </Container>
        </Navbar>
    );
}

export default CNavbar;