import React from 'react';
import {STYLES} from "../../App";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

const Homestaging = () => {
    return (
        <div>
            <Helmet>
                <meta name={'description'}
                      content={'Хоумстейджинг в Екатеринбурге, от 1 тыс. руб./м2 &#128383; 8-912-24-93-713'}/>
                <title>Хоумстейджинг в Екатеринбурге | Flippium</title>
            </Helmet>
            <Container className={STYLES.cards} fluid={'true'}>
                <h1>Хоумстейджинг в Екатеринбурге</h1>
                Хоумстейджинг - это способ предпродажной подготовки квартиры или дома, создание привлекательного образа. Цель хоумстейджинга заключается в том, чтобы покупатель или арендатор
                смог увидеть свою будущую жизнь в квартире и влюбился в эту картинку. При хоумстейджинге полноценного ремонта, как правило, не требуется — достаточно минимальных вложений,
                чтобы убраться, переставить мебель и наполнить обстановку декором
                <hr/>
                Если Вам требуется услуги хоумстейджинга - <a href={'/contacts'}>напишите нам</a>
            </Container>

        </div>
    );
};

export default Homestaging;